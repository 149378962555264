.unified-sales-project-management {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-top: 5%;
  }
  
  h1 {
    color: #f0f0f0;
    font-size: 20px;
  }
  
  .subtitle {
    color: #666;
    margin-bottom: 30px;
  }
  
  .features-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .feature {
    width: 30%;
  }
  
  .icon {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon svg {
    width: 60px;
    height: 60px;
    color: #007bff;
  }
  
  .cta-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 20px;
  }
  
  .checkbox-label {
    color: #666;
    cursor: pointer;
  }
  .checkbox-label:hover{
    text-decoration-line: underline;
  }