.navbar {
    z-index: 11;
    border-bottom: 1px solid white;
  }
  
  .navbar-nav .nav-item {
    margin-right: 20px;
    color: white;
    font-weight: 600;
  }
  .navbar-nav .nav-item.active {
    border-radius: 50px; /* Border radius */
    border: 1px solid white; /* Border color */
    color: white;
    padding: 7px 30px;
  }
  
  .landing-page {
    background-image: linear-gradient(to right, #0e377d, #f3722c);
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  .landing-page-login-btn {
    background-color: white;
    padding: 10px 30px;
    border-radius: 50px;
    font-weight: 600;
    border: 1px solid white;
  }
  .start-free-traial {
    color: white;
    margin-left: 20px;
  }
  .body-part {
    padding: 50px;
  }
  .landing-page-navbar-button {
    background-color: whitesmoke;
    border-radius: 50px;
    padding: 7px 10px 7px 20px;
    font-weight: 600;
  }
  .landing-page-navbar-button:hover{
    background-color: whitesmoke;
    color: black;
  }
  .landing-page-first-column {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 60vh; */
  }
  .landing-page-second-column {
    /* height: 70vh; */
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  @media only screen and (max-width: 600px) {
    .landing-page-login-btn{
      padding: 7px 20px;
    }
    .start-free-traial{
      margin-left: 10px;
    }
    .navbar-nav .nav-item.active{
      border: none;
      padding: 0;
    }
  }