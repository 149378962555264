.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.css-192oodb-MuiButtonBase-root-MuiButton-root {
  min-width: 64px !important;
    padding: 6px 8px !important;
}
.css-1cyz38m-MuiTypography-root {
  margin-left: -35px !important;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .css-192oodb-MuiButtonBase-root-MuiButton-root {
    min-width: 24px !important;
    padding: 2px 2px !important;
  }
  .css-1o5th6x {
    margin: 32px 0px 0px -30px !important;
  }
  .css-1cyz38m-MuiTypography-root {
    margin-left: -20px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-siyejb{
  padding-bottom: 1rem;
}

/* Dashboard logo
.logo{
  height: auto;
} */

.websiteName{
  margin-left: -1%;
  padding-top: 0.3%;
}
.custom-style .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  position:fixed !important;
  background: #1F2A40 !important;
}
.css-y0u0hj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 15px !important;
  position: sticky !important;
  top: 0 !important;
  /* background-color: black !important; */
}

.pro-sidebar > .pro-sidebar-inner {
  background: none !important;
  height: 100%;
  position: relative;
  z-index: 101;
}

.css-1j1s727 .pro-sidebar-inner {
  background: none !important;
}

.css-1oxbmhs-MuiTypography-root {

  width: 95%;
}

.css-1o5th6x {
  margin: 10px 0px 0px -30px !important;
}

.css-1lb1b6k-MuiPaper-root {
  margin-top: 6% !important;
}

@media (min-width: 100px) and (max-width:450px) {
  .css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper {
    width: 194px !important;
    box-sizing: border-box;
  }
}
.listViewStyle {
  padding: 10px;
  height: 76vh;
  margin-top: 20px;
  overflow: auto;
  color: black;
}

@media (min-width: 1201px) and (max-width: 1380px) {
  .listViewStyle {
    height: 70vh;
    margin-top: 12px;
  }
}
.css-ofrcno-MuiGrid-root>.MuiGrid-item {
  padding-left: 70px !important;
}
.css-y0u0hj{
  background: rebeccapurple !important;
  padding: 10px !important;
}
.css-1cyz38m-MuiTypography-root {
  margin-top: 10px !important;
}
.css-3nargb > .MuiGrid-item {
  padding-left: 50px !important;
}
.underline-on-hover {
  position: relative;
}
.underline-on-hover:hover {
  text-decoration: none;
}
.underline-on-hover:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px; /* Adjust as needed */
  width: 100%;
  border-bottom: 5px solid white; /* Adjust color and style */
}

.more-modules:hover::after {
  content: "Other Modules";
  font-size: 14px;
  position: absolute;
  top: 50px; /* Adjust as needed */
  width: 300%;
  background-color: gray;
  border-radius: 10px;
  padding: 10px;
}
.css-zwiamn {
  margin-top: 110px !important;
 }
 
@media (min-width: 390px) and (max-width: 500px) {
  .css-ofrcno-MuiGrid-root>.MuiGrid-item {
    padding-left: 19px !important;
}
.css-3nargb>.MuiGrid-item {
  padding-left: 25px !important;
}
  .enterprise{
    font-size: 10px !important;
  }
  .css-1lb1b6k-MuiPaper-root {
    margin-top: 17% !important;
}
  .css-1cyz38m-MuiTypography-root {
    font-size: 11px !important;
    margin-left: 0px !important;
  }
  .css-13rep3f-MuiPaper-root {
    min-height: 133px !important;

  }
  .imageMobile{
   width: 20px !important;
   margin-top: 7px;
  }
 .addIcon {
  cursor: pointer;
  font-size: 31px !important;
  margin-top: 15px !important;
  margin-left: 15px !important;
}
 .unified-sales-project-management {
  margin-top: 20% !important;
 }

 .css-1o5th6x {
  margin: 38px 0px 0px -26px !important;
 }
 .css-17w9904-MuiTypography-root {
  font-size: 69.5% !important;
  margin-top: 6px !important;
  margin-left: 5px !important;
 }
 .css-zplgev {
  margin-top: 18.2% !important;
}
.css-1nty1pq-MuiButtonBase-root-MuiButton-root {
  margin-right: 5px !important;
  font-size: 0.7rem !important;
}
.css-y3n1jv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  line-height: 1.3em !important;
}
  .css-xc4s15 {
    width: 572%  !important;
    height: 39vh !important;
}
.listViewStyle{
  width: 120% !important;
}
.css-1nty1pq-MuiButtonBase-root-MuiButton-root {
  padding: 6px 6px !important;
}

.css-3lgpxb-MuiButtonBase-root-MuiButton-root {
  padding: 6px 6px !important;
}
.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 6px !important;
}

.css-1dl8byk {
  width: 569% !important;
  height: 50vh !important;
}

.css-1on7o35-MuiGrid-root {
  flex-basis: 126.166667% !important;
  max-width: 92.166667% !important;
  margin: 22px 10px !important;
}



.css-1m4l1rg-MuiPaper-root {
  height: 20% !important;
}

.css-1bc7ypc {
  width: 581% !important;
  margin-top: 123% !important;
  height: 60vh !important;
}
.css-13rep3f-MuiPaper-root {
  min-height: 80px !important;
}
.listViewStyle {
  width: 100% !important;
}

 }


 @media only screen and  (max-width: 389px) {
  .css-ofrcno-MuiGrid-root>.MuiGrid-item {
    padding-left: 19px !important;
}
.css-3nargb>.MuiGrid-item {
  padding-left: 25px !important;
}
  .css-1lb1b6k-MuiPaper-root {
    margin-top: 18% !important;
  }
  .css-1cyz38m-MuiTypography-root {
    font-size: 11px !important;
  }
  .css-13rep3f-MuiPaper-root {
    min-height: 133px !important;

  }
  .websiteName{
    margin-left: 0.5% !important; 
    padding-top: 1.3% !important;
  }

  .css-1na9ye4 {
    padding: 10px !important;
    
}
  .imageMobile{
   width: 20px !important;
   margin-top: -1px;
  }
  .addIcon {
    font-size: 26px !important;
    cursor: pointer;
    margin-top: 6px;
}
 .unified-sales-project-management {
  margin-top: 20% !important;
 }

 .css-1o5th6x {
  margin: 30px 0px 0px -21px !important;
 }
 .css-17w9904-MuiTypography-root {
  font-size: 69.5% !important;
  margin-top: 6px !important;
  margin-left: 5px !important;
  width: 62px !important;
}
 .css-zplgev {
  margin-top: 18.2% !important;
}
.css-1nty1pq-MuiButtonBase-root-MuiButton-root {
  margin-right: 5px !important;
  font-size: 0.7rem !important;
}
.css-y3n1jv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  line-height: 1.3em !important;
}
.css-xc4s15 {
  width: 572%  !important;
}
.listViewStyle{
  width: 120% !important;
}
.css-1nty1pq-MuiButtonBase-root-MuiButton-root {
  padding: 6px 6px !important;
}

.css-3lgpxb-MuiButtonBase-root-MuiButton-root {
  padding: 6px 6px !important;
}
.css-1xfpnb4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 6px !important;
}

.css-1dl8byk {
  width: 569% !important;
  height: 50vh !important;
}

.css-1on7o35-MuiGrid-root {
  flex-basis: 126.166667% !important;
  max-width: 92.166667% !important;
  margin: 22px 10px !important;
}



.css-1m4l1rg-MuiPaper-root {
  height: 20% !important;
}

.css-1bc7ypc {
  width: 581% !important;
  margin-top: 123% !important;
}
 }


 @media (min-width: 501px) and (max-width: 600px){
  .css-ofrcno-MuiGrid-root>.MuiGrid-item {
    padding-left: 19px !important;
}
.css-3nargb>.MuiGrid-item {
  padding-left: 25px !important;
}
.css-3nargb>.MuiGrid-item {
  padding-left: 25px !important;
}
  .websiteName{
    padding-top: 1.6% !important;
   }
   .css-zplgev {
    margin-top: 15.2% !important;
  }



}/*end emdia query */


@media (min-width: 601px) and (max-width: 769px){
  .websiteName{
   padding-top: 1.3% !important;
  }
  .css-zplgev {
    margin-top: 14.2% !important;
  }
}/*end emdia query */

@media (min-width: 601px) and (max-width: 769px){
  .websiteName{
   padding-top: 1.3% !important;
  }
  .css-zplgev {
    margin-top: 14.2% !important;
  }
}/*end emdia query */

@media (min-width: 770px) and (max-width: 1000px){
  .websiteName{
   padding-top: 1.1% !important;
  }
}/*end emdia query */

@media (min-width: 1001px) and (max-width: 1200px){
  .websiteName{
   padding-top: 0.8% !important;
  }
  .css-zplgev {
    margin-top: 7.2% !important;
  }
}/*end emdia query */

@media (min-width: 1201px) and (max-width: 1380px){
  .css-xc4s15 {
    margin-top: 12px !important;
    height:70vh !important;
  }
  .css-zplgev {
    margin-top: 6.3% !important;
  }
  .css-1o5th6x {
    margin: 32px 0px 0px -30px !important;
  }
}/*end emdia query */

@media (min-width: 675px) and (max-width: 704px){
  .css-zplgev {
    margin-top: 13.3% !important;
  }
}/*end emdia query */


@media (min-width: 705px) and (max-width: px){
  .css-zplgev {
    margin-top: 13.3% !important;
  }
}/*end emdia query */


.css-1m4l1rg-MuiPaper-root {
  color: black !important;
}

.css-7c4e8b-MuiPaper-root {
  color: black !important;
}

.css-1m4l1rg-MuiPaper-root {
  height: 15%;
}

.css-1x66n1k-MuiTypography-root {
  font-size: 1.2rem !important;
}

.css-7c4e8b-MuiPaper-root {
  padding: 2% 6% !important;
  width: 112% !important;
  margin-left: -15px !important;
}


.css-9gpylz {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

/* 
.css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  height: 32vh;
} */


.css-io39u6-MuiDataGrid-root {
    height: 94% !important;
}

.css-112ke3r {
  height: 14% !important;
}

.css-11i48vv {
  color: black !important;
  margin-left: -16px !important;
  margin-right: -16px !important;
}


.css-112ke3r {
  color: black !important;
}

.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 0 5px;
  height: 0.6em !important;
  padding: 17.5px 10px !important;
}
.css-1rv476z-MuiInputBase-input-MuiFilledInput-input {
  height: 0.6em !important;
}
.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 10px !important;
}
.css-9425fu-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0px !important;
  left: 0;
}
.css-1fuaiuw {
  display: grid;
  gap: 17px !important;
}
.css-nh22sz-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 16px !important;
}
.css-1476h24 {
  height: 0.6em !important;
}
.css-h4os0j {
  height: 0.6em !important;
}
.css-nqlg3w {
  text-align: left;
  position: absolute;
  inset: 0px 0px 0px !important;
}
.css-isbt42 > .MuiGrid-item {
  padding-top: 11px !important;
}
.css-1x3ougv-MuiTypography-root {
  margin-bottom: 1px !important;
}

@media (max-width: 600px) {
  .listViewStyle {
    padding: 10px;
  }
}


@media (min-width: 600px) {
  .css-1u4bi03-MuiButtonBase-root-MuiIconButton-root {
      padding: 16px !important;
  }
}

