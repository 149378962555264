.crm-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0px;
    background-color: #f5f5f5;
    border-top: 1px solid #e0e0e0;
    position: fixed;
    bottom: 0%;
    width: 100%;
    z-index: 1000;
    height: 4vh;
    margin-top: 20px;
  }
  
  .footer-left, .footer-right {
    display: flex;
    align-items: center;
  }
  
  .nav-button {
    background: none;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .icon-button {
    background: none;
    border: none;
    font-size: 16px;
    color: #555;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .help-button {
    background-color: #7f57ff;
    color: white;
    border: none;
    /* border-radius: 50%; */
    width: 35px;
    height: 30px;
    font-size: 12px;
    cursor: pointer;
   
  }